import { useCallback } from 'react';
import useFilter from './useFilter';

const useFilterRoutes = (routes, location = []) => {
	const callback = useCallback(
		({
			route: {
				anchors: { text },
				path
			}
		}) => ({
			text,
			path
		}),
		[]
	);
	return useFilter(routes, ['route', 'anchors', location], callback);
};

export default useFilterRoutes;
