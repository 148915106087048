import { useMemo } from 'react';
import Fuse from 'fuse.js';
import categories from '../../data/categories.json';
var options = {
	shouldSort: true,
	threshold: 0.25,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: ['name', 'category', 'location']
};

const useAPI = (get, dbData, setDBData) =>
	useMemo(() => {
		return {
			getPageData: async page => {},
			getGroup: async id => {
				if (dbData['group']) return dbData['group'];
				const { status, ...res } = await get({ endpoint: `group`, methods: [['child', id]] });
				if (status === 'success') {
					setDBData({ group: res.data });
					return res.data;
				} else {
					return false;
				}
			},
			getGroups: async () => {
				if (dbData['groups']) return dbData['groups'];
				const { status, ...res } = await get({ endpoint: 'groups' });
				if (status === 'success') {
					const { data = {} } = res;
					if (!data) return [];
					const groups = Object.keys(data).map(key => {
						const { category, ...groupData } = data[key];
						return { ...groupData, category: categories[category], key };
					});

					setDBData({ groups });
					return groups;
				} else {
					return false;
				}
			},
			search: (data, query, limit = null) => {
				let fuse = dbData['fuse'];
				if (!fuse) {
					fuse = new Fuse(data, options);
					setDBData({ fuse });
				}
				const result = fuse.search(query);
				return limit && limit < result.length ? result.slice(0, limit) : result;
			}
		};
	}, [dbData, setDBData, get]);

export default useAPI;
