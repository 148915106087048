import React from 'react';
import PropTypes from 'prop-types';
import InputWrap from './InputWrap';

const Input = React.memo(
	({
		Tag = 'input',
		type = 'text',
		id,
		label,
		changeHandler = ({ target: { value } }) => value,
		onChange,
		maxWidth,
		context,
		value,
		isValid,
		children,
		icon,
		...props
	}) => {
		return (
			// A max-width can be set directly on the input-wrap element to limit the total width of the input field
			<InputWrap {...{ value, isValid, maxWidth, label, id, icon }}>
				<Tag
					className="input-field"
					id={`input-${id}`}
					type={type}
					value={value ? value : ''}
					onChange={e => onChange({ value: changeHandler(e), type, id })}
					{...props}
				/>
				{children}
			</InputWrap>
		);
	}
);

Input.propTypes = {
	Tag: PropTypes.oneOf(['input', 'textarea']),
	type: PropTypes.oneOf(['text', 'checkbox', 'email', 'number', 'tel', 'url', 'search']),
	id: PropTypes.string,
	label: PropTypes.string,
	isValid: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	name: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	changeHandler: PropTypes.func
};

export default Input;
