import React from 'react';
import { Block, Width, Editor } from '../../Layout';

const GDPR = () => {
	return (
		<>
			<Block>
				<Width size="s">
					<Editor>
						<h3>Personvern</h3>
						<p>Du må ha fylt 18 år for å kunne benytte denne siden.</p>
						<p>All brukerdata du velger å skrive i innsendingsskjemaet lagres.</p>
						<p>
							Fordi det ikke er nødvendig å sende inn personlige data for å benytte siden, vil data du skriver inn ikke
							bli behandlet som personlige data. 
						</p>
						<p>
							Det er opp til deg som bruker å ta ansvar for å ikke dele data du ikke ønsker at andre skal få tilgang
							til.
						</p>
						<p>Arla forbeholder seg retten til å fjerne innhold som oppfattes støtende.</p>
						<p>
							Dersom innhold oppfattes som støtende, eller inneholder data som ikke er delt med samtykke, kan du sende
							en epost til <a href="mailto:arla@jcpnordic.com">arla@jcpnordic.com</a> med link til innlegget og/eller ID
							til gjeldende gruppe.
						</p>
					</Editor>
				</Width>
			</Block>
		</>
	);
};

export default GDPR;

// REDACTED
// Brukerdata, informasjon om treningsgruppen
// og offentlige bilder fra gruppene, kan bli brukt til å markedsføre nettsiden. Ved å melde på din gruppe
// samtykker du til at Arla kan bruke denne informasjonen i egne og kjøpte kanaler på ubestemt tid.
