import { useEffect } from 'react';

const useDebouncedCallback = (value, { delay = 350 }, callback) => {
	useEffect(() => {
		const token = setTimeout(() => callback(value), delay);
		return () => clearTimeout(token);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);
};

export default useDebouncedCallback;
