import { useState, useCallback } from 'react';

const useActionData = defaultData => {
	const [data, setData] = useState(defaultData);
	const setFunction = useCallback(data => {
		setData(store => ({ ...store, ...data }));
	}, []);
	return [data, setFunction];
};

export default useActionData;
