import React from 'react';
import PropTypes from 'prop-types';

const Container = ({ size = 'l', children }) => <div className={`container-${size}`}>{children}</div>;

Container.propTypes = {
	size: PropTypes.oneOf([
		't',
		'tt',
		't-top',
		't-bottom',
		's',
		'ss',
		's-top',
		's-bottom',
		'm',
		'mm',
		'm-top',
		'm-bottom',
		'l',
		'll',
		'l-top',
		'l-bottom',
		'h',
		'hh',
		'h-top',
		'h-bottom'
	])
};

export default Container;
