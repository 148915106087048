import React from 'react';
import Input from './Input';

const Checkbox = props => (
	<Input type="checkbox" {...props} checked={props.value} changeHandler={({ target: { checked } }) => checked}>
		<label htmlFor={'input-' + props.id} className="input-field-clone" />
	</Input>
);

export default Checkbox;
