import React, { useState } from 'react';
import Form from '../Form';

import searchIcon from '../../media/icons/search.svg';
import useDebouncedCallback from '../hooks/useDebouncedCallback';
import { Block, Layout } from '../Layout';

const SearchInput = ({
	onChange = () => {},
	onSubmit = () => {},
	button,
	debounceDelay = 200,
	defaultValue,
	placeholder
}) => {
	const [value, setValue] = useState('');
	useDebouncedCallback(value, { delay: debounceDelay }, value => onChange(value));
	return (
		<Form onSubmit={onSubmit} onChange={({ 'page-search': value }) => setValue(value)} validate={false}>
			<Block>
				<Form.Input
					{...{
						id: 'page-search',
						maxWidth: '400px',
						type: 'search',
						icon: searchIcon,
						validation: 'text',
						maxLength: 32,
						defaultValue: defaultValue,
						placeholder: placeholder
					}}
				/>
			</Block>
			{button ? (
				<Block size="s">
					<Layout center={true}>
						<Form.Submit disabled={!value}>{button}</Form.Submit>
					</Layout>
				</Block>
			) : null}
		</Form>
	);
};

export default SearchInput;
