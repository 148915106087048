import React from 'react';
import Input from './Input';

const InputButton = ({ onClick, ...props }) => (
	<Input type="button" {...props}>
		<button type="button" onClick={onClick} className="input-submit" disabled={!props.isValid} />
	</Input>
);

export default InputButton;
