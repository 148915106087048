import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-navi';

const Btn = ({
	type = 'prim',
	to = null,
	href = null,
	Tag = 'button',
	meta = { before: false, after: false },
	icon = { before: false, after: false },
	onClick,
	disabled = false,
	htmlType = Tag === 'button' ? 'button' : null,
	children,
	...props
}) => (
	<Tag className={`btn-${type}`} type={htmlType} disabled={disabled} to={to} href={href} onClick={onClick} {...props}>
		{icon.before ? <span className="btn-icon" /> : null}
		<span className="btn-text">
			{meta.before ? <span className="btn-text-meta">{meta.before}</span> : null}
			{children}
			{meta.after ? <span className="btn-text-meta">{meta.after}</span> : null}
		</span>
		{icon.after ? <span className="btn-icon" /> : null}
	</Tag>
);

Btn.A = props => <Btn Tag="a" target="_blank" rel="noreferrer" {...props} />;
Btn.Link = props => <Btn Tag={Link} {...props} />;

/**
 * Update and add propTpes
 */

Btn.propTypes = {
	type: PropTypes.oneOf(['prim', 'sec', 'tert', 'text']),
	href: PropTypes.string,
	disabled: PropTypes.bool,
	onClick: PropTypes.func,
	Tag: PropTypes.any,
	htmlType: PropTypes.string,
	meta: PropTypes.shape({
		before: PropTypes.string,
		after: PropTypes.string
	}),
	icon: PropTypes.shape({
		before: PropTypes.string,
		after: PropTypes.string
	})
};

export default Btn;
