import { useState, useEffect } from 'react';
import promise from '../../tools/promise';
import PATHS from './PATHS';

export const useActions = firebase => {
	const [actions, setActions] = useState();

	useEffect(() => {
		if (!firebase) return;
		const firebaseDB = firebase.database();
		setActions(() => path => {
			if (!PATHS[path]) return { error: console.error('Illegal database path: ' + path) };
			const dbRef = firebaseDB.ref(PATHS[path].path);

			if (path === 'timestamp') {
				return promise(async (resolve, reject, { childKey }) => {
					const ref = dbRef;
					ref.set({ [childKey]: firebase.database.ServerValue.TIMESTAMP }).then(() => {
						resolve(ref);
					});
				});
			}
			return {
				push: promise(async (resolve, reject) => {
					if (!PATHS[path].write) return console.error('Illegal database permission: ' + path);
					dbRef
						.push()
						.then(ref => resolve(ref))
						.catch(reject);
				}),
				update: promise(async (resolve, reject, { data, ref, key, timestamp = true }) => {
					if (!PATHS[path].write) return console.error('Illegal database permission: ' + path);
					if (!ref && !key) return console.error('No ref or id for update method');
					ref = ref ? ref : firebaseDB.ref(`${PATHS[path].path}/${key}`);

					ref
						.update({ ...data, ...(timestamp ? { timestamp: firebase.database.ServerValue.TIMESTAMP } : {}) })
						.then(() => {
							resolve(ref);
						})
						.catch(reject);
				}),
				set: promise(async (resolve, reject, { data, childKey, timestamp = true }) => {
					if (!PATHS[path].write) return console.error('Illegal database permission: ' + path);
					const ref = childKey ? dbRef.child(childKey) : dbRef;
					ref
						.set({ ...data, ...(timestamp ? { timestamp: firebase.database.ServerValue.TIMESTAMP } : {}) })
						.then(() => {
							resolve(ref);
						})
						.catch(reject);
				}),
				get: async ({ dbCall = 'once', methods = [] }) => {
					if (!PATHS[path].read) return console.error('Illegal database path: ' + path);
					let ref = dbRef;

					for (const [method, value] of methods) {
						ref = dbRef[method](value);
					}

					return ref[dbCall]('value');
				}
			};
		});
	}, [firebase]);

	return actions;
};
