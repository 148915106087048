import { useState, useEffect } from 'react';
import Firebase from './Firebase';

const useFirebase = connect => {
	const [firebase, setFirebase] = useState();
	useEffect(() => {
		if (!connect) return;
		const initFirebase = async () => {
			const firebase = await Firebase();
			setFirebase(firebase);
		};
		initFirebase();
	}, [connect]);

	return firebase;
};

export default useFirebase;
