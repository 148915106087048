import React from 'react';

const CardRows = ({ rows }) =>
	rows.map((cols = [], i) => (
		<div key={i}>
			<div className="card-row">
				{cols.map((value, i) => (
					<div key={value + i} className="card-col type-uppercase">
						{value}
					</div>
				))}
			</div>
		</div>
	));

export default CardRows;
