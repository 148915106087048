import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const Textarea = ({ rows = 4, ...props }) => <Input Tag="textarea" rows={rows} {...props} />;

Textarea.propTypes = {
	rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Textarea;
