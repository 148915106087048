/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef } from 'react';

const useWait = (trigger, timeout = 10000) => {
	const resRef = useRef();
	const promise = useMemo(
		() =>
			new Promise(res => {
				resRef.current = res;
			}),
		[]
	);

	useEffect(() => {
		if (!trigger) return;
		resRef.current(true);
	}, [trigger]);

	useEffect(() => {
		if (trigger === null) return;

		const timeoutRef = setTimeout(() => {
			if (trigger) return;
			resRef.current(false);
		}, timeout);

		return () => clearTimeout(timeoutRef);
	});

	return promise;
};

export default useWait;
