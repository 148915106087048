import React, { useContext, useState } from 'react';
import { useNavigation } from 'react-navi';
import { StateContext } from '../../State';

import Block from '../../Layout/Block';
import Width from '../../Layout/Width';

import Modal from '../../Global/Modal';

import Form from '../../Form/Form';
import formData from './formData.json';
import { Layout } from '../../Layout';
import { Btn } from '../../Elements';
import Card from '../../Elements/Card';

const Submit = () => {
	const [open, setOpen] = useState(false);
	const { setUserData, userData = {}, submit } = useContext(StateContext);
	const navigation = useNavigation();
	return (
		<>
			<Block>
				<Width size={'s'}>
					<h2 className="type-t2 type-center type-uppercase">Finn trenings&shy;gleden sammen med andre</h2>
				</Width>
			</Block>
			<Block>
				<Form
					onChange={setUserData}
					onSubmit={async () => {
						const res = await submit();
						navigation.navigate(`/registrer-suksess/${res.key}`);
					}}
				>
					{formData.map(({ elem, ...props }, i) => {
						const Elem = Form[elem];
						return (
							<Block size="m" key={props.id ? props.id : i}>
								<Width size="s">
									<Elem {...props} defaultValue={userData[props.id]} />
								</Width>
							</Block>
						);
					})}
					<Block size="m">
						<Width size="s">
							<Layout>
								<Form.Btn onClick={() => setOpen(true)} {...{ meta: { after: '+' } }}>
									Registrer din treningsgruppe
								</Form.Btn>
							</Layout>
						</Width>
					</Block>
					{open ? (
						<Modal open={true} closeFunc={() => setOpen(false)}>
							<Card
								Tag="div"
								title="Lagre din treningsgruppe?"
								description="Du kan ikke gjøre endringer etter at treningsgruppen din er lagret."
							>
								<Block size="s">
									<Btn type="sec" style={{ marginRight: '20px' }} onClick={() => setOpen(false)}>
										Tilbake
									</Btn>
									<Form.Submit htmlType="submit">Ja, jeg vil lagre</Form.Submit>
								</Block>
							</Card>
						</Modal>
					) : null}
				</Form>
			</Block>
		</>
	);
};

export default Submit;
