import React from 'react';
import cc from 'classcat';
import { Block } from '../Layout';

const Spinner = ({ style, p, className }) => {
	// let spinner = (
	// 	<div className="spinner-container">
	// 		<figure className={cc(['spinner-img'])}>
	// 			<img src={spinnerImg} alt="Spinner" />
	// 		</figure>
	// 		<div className={cc(['spinner', 'bg-prim'])} />
	// 	</div>
	// );

	return (
		<div style={{ ...style }} className={cc(['spinner-overlay', className])}>
			<Block>
				<div className="lds-ring">
					<div />
					<div />
					<div />
					<div />
				</div>
			</Block>
			{p ? (
				<Block>
					<div className="editor editor-default editor-center">
						<p>{p}</p>
					</div>
				</Block>
			) : null}
		</div>
	);
};

export default Spinner;
