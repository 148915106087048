import React from 'react';

import { useNavigation } from 'react-navi';

import Btn from '../../Elements/Btn';

import Block from '../../Layout/Block';
import Layout from '../../Layout/Layout';
import Width from '../../Layout/Width';
import Editor from '../../Layout/Editor';
import SearchInput from '../../SearchInput/SearchInput';

const Home = () => {
	const navigation = useNavigation();
	return (
		<>
			<Block>
				<Width size="m">
					<h1 className="type-t1 type-center type-uppercase">Finn trenings&shy;gleden sammen med andre</h1>
				</Width>
			</Block>
			<Block>
				<Editor>
					<h3 className="type-uppercase type-center">Finn din nye treningsgruppe</h3>
				</Editor>
			</Block>
			<Block size="s">
				<Width size={'s'}>
					<SearchInput
						onSubmit={({ 'page-search': value }) => {
							navigation.navigate(`./finn-treningsgruppe?search=${value}`);
						}}
						placeholder="Søk etter sted eller treningsform"
						button={'Søk og finn treningsgruppe'}
					/>
				</Width>
			</Block>
			<Block size="s">
				<Editor>
					<h3 className="type-uppercase type-center">eller</h3>
				</Editor>
			</Block>
			<Block size="s">
				<Width>
					<Layout>
						<Btn.Link href={'/registrer'} type={'sec'} meta={{ after: '+' }}>
							Legg til din treningsgruppe
						</Btn.Link>
					</Layout>
				</Width>
			</Block>
		</>
	);
};

export default Home;
