import React from 'react';
import PropTypes from 'prop-types';

// Vendor
import cc from 'classcat';

const Editor = ({ size = null, center = false, children }) => (
	<div className={cc([`editor${size ? `-${size}` : ''}`, { 'editor-center': center }])}>{children}</div>
);

Editor.propTypes = {
	size: PropTypes.oneOf(['s', 'l']),
	center: PropTypes.bool
};
export default Editor;
