import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

const Layout = ({ center = true, middle = false, unknown = false, children }) => (
	<div
		className={cc([
			{
				'layout-center': center,
				'layout-middle': middle,
				'layout-unknown': unknown
			}
		])}
	>
		{children}
	</div>
);

Layout.propTypes = {
	center: PropTypes.bool,
	middle: PropTypes.bool,
	unknown: PropTypes.bool
};

export default Layout;
