export default type => {
	switch (type) {
		case 'number':
			return number => /[0-9]$/.test(number);

		case 'phone':
			return number => (!number || number.length < 8 ? false : /^[2345679]+[0-9]+$/.test(number));

		case 'email':
			return email =>
				/^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i.test(
					email
				);

		case 'date':
			return date =>
				/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(
					date
				);

		case 'text':
			return value => !!value;

		case 'datalist':
			return () => true;

		case 'file':
			return elem => typeof elem.prop('files')[0] === 'undefined';

		case 'radio':
			return data => data !== null;

		case 'grade':
			return data => (data === '' ? true : /^[8-9]$|^10$|^[8-9][a-zA-Z]+|^10[a-zA-Z]+$/.test(data));

		case 'checkbox':
			return data => !!data;

		case 'url':
			return url =>
				/^(https:[/][/])*(www.)*facebook.com([/][a-zA-ZæøåÆØÅ0-9._-]+)?[/][a-zA-ZæøåÆØÅ0-9._-]+[/]?$/.test(url);

		default:
			return () => true;
	}
	// case 'code': return () => true,

	// radio: return elem => {
	// 	return !!document.querySelectorAll('input[name="' + elem.name + '"]:checked').value;
	// }}
};

export const preventInvalidInput = function(e) {
	const which = e.which ? e.which : null;
	if (
		!(
			which <= 0 || // arrow keys
			which === 8 || // delete key
			which === 9 || // tab Key
			(which >= 96 && which <= 105) || // 0-9 keypad on Safari and Firefox
			(which >= 48 && which <= 57)
		)
	)
		e.preventDefault();
};
