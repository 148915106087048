/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';

// Elements
import { Btn } from '../Elements';

// Components
import FormElements from './FormElements/';

// Functions
import { useFormData, useValidateForm } from './form-functions';

// Tools
import objectMap from '../../tools/object-map';

const Form = ({ onSubmit = () => {}, onChange = () => {}, validate = true, children }) => {
	const [formState, dispatchData] = useFormData(validate);
	const [formValues, setFormValues] = useState({});
	const isValid = useValidateForm(formState, validate);
	useEffect(() => {
		setFormValues(objectMap(formState, ({ value }, key) => ({ [key]: value })));
	}, [formState]);

	useEffect(() => {
		onChange(formValues);
	}, [formValues]);

	return (
		<form
			className={isValid ? 'form-valid' : ''}
			onSubmit={e => {
				e.preventDefault();
				if (!isValid) return;

				onSubmit(formValues);
			}}
			disabled={!isValid}
		>
			<Form.Provider value={{ formState, isValid, onChange: dispatchData }}>{children}</Form.Provider>
		</form>
	);
};

Form.context = React.createContext({});
Form.Provider = Form.context.Provider;

const FormElement = React.memo(({ elem, defaultValue = '', validation = 'text', required = false, ...props }) => {
	const {
		formState: { [props.id]: elemState = {} },
		onChange
	} = useContext(Form.context);
	const Elem = FormElements[elem];

	const { isValid = false, value = defaultValue } = elemState;
	useEffect(() => {
		onChange({ value, type: props.type, validation, id: props.id, required });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Elem {...{ isValid, value, onChange }} {...props} />;
});

const FormButton = ({ onClick, htmlType, children }) => {
	const { isValid } = useContext(Form.context);
	return (
		<Btn onClick={onClick} htmlType={htmlType} disabled={!isValid}>
			{children}
		</Btn>
	);
};

// const elems = [
// 	['Input', 'Input'],
// 	['Number', 'InputNumber'],
// 	['Checkbox', 'InputCheckbox'],
// 	['Textarea', 'InputTextarea'],
// 	['Button', 'InputButton'],
// 	['Select', 'Select']
// ];

// for (const [alias, componentName] of elems) {
// 	Form[alias] = props => <FormElement elem={componentName} {...props} />;
// }

Form.Input = props => <FormElement elem="Input" {...props} />;
Form.Number = props => <FormElement elem="InputNumber" {...props} />;
Form.Checkbox = props => <FormElement elem="InputCheckbox" {...props} />;
Form.Textarea = props => <FormElement elem="InputTextarea" {...props} />;
Form.Select = props => <FormElement elem="Select" {...props} />;

Form.Btn = props => <FormButton {...props} />;
Form.Submit = props => <FormButton htmlType="submit" {...props} />;

export default Form;
