import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

const Block = ({ size = 'l', backgroundColor = null, borderBottomColor = null, inherit = false, children }) => {
	return (
		<div
			className={cc([`block-${size}`, { 'block-inherit': inherit }])}
			style={{
				backgroundColor: backgroundColor ? `#${backgroundColor}` : null,
				borderBottom: borderBottomColor ? `1px solid #${borderBottomColor}` : null
			}}
		>
			{children}
		</div>
	);
};

Block.propTypes = {
	size: PropTypes.oneOf([
		'0',
		't',
		's',
		'm',
		'l',
		'h',
		'0-static',
		't-static',
		's-static',
		'm-static',
		'l-static',
		'h-static'
	]),
	backgroundColor: PropTypes.string,
	borderBottomColor: PropTypes.string,
	inherit: PropTypes.bool
};

export default Block;
