import React from 'react';
import { Block, Width } from '../../Layout';

const NotFound = () => {
	return (
		<>
			<Block>
				<Width>
					<h1 className="type-t1 type-center type-uppercase">Denne siden eksisterer ikke</h1>
				</Width>
			</Block>
		</>
	);
};

export default NotFound;
