import React, { Suspense } from 'react';
import { Router, View, NotFoundBoundary } from 'react-navi';

// Globals
import Menu from './Components/Global/Menu';
import Main from './Components/Global/Main';
import Footer from './Components/Global/Footer';

// Hooks
import useFilterRoutes from './Components/hooks/useFilterRoutes';
import useRoutes from './Components/Router/useRoutes';

// Constants
import routesJSON from './CONSTANTS/routes.json';
import NotFound from './Components/Global/NotFound';

function App({ submission }) {
	const routes = useRoutes();

	const menuRoutes = useFilterRoutes(routesJSON, 'menu');
	const footerRoutes = useFilterRoutes(routesJSON, 'footer');

	return (
		<Router routes={routes} context={{ submission }}>
			<Menu routes={menuRoutes} />
			<Main>
				<NotFoundBoundary render={NotFound}>
					<Suspense fallback={null}>
						<View />
					</Suspense>
				</NotFoundBoundary>
			</Main>
			<Footer routes={footerRoutes} />
		</Router>
	);
}

export default App;
