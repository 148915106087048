// import React, { useEffect, useState } from 'react';
import React from 'react';

// import getRatioOfImg from '../../../tools/getRatioOfImg';
// import useAwaitImgLoad from '../../Hooks/useAwaitImgLoad';

const Figure = ({ type = 'default', src, alt, title = alt, url = null, figcaption = null, ...props }) => {
	// const [padding, setPadding] = useState();
	// const img = useAwaitImgLoad(src);
	// useEffect(() => {
	// 	if (!img) return;
	// 	const ratio = getRatioOfImg(img);
	// 	if (!ratio) return;
	// 	setPadding(ratio);
	// }, [img]);

	// <figure className={`figure-${type}`} style={{ paddingBottom: `${padding}%`, height: 0 }}>

	return (
		<figure className={`figure-${type}`} {...props}>
			{url ? (
				<a href={url} className="figure-anchor">
					<div className="type-hide-text">
						This anchor makes the image below into a button with the following URL: {url}
					</div>
				</a>
			) : null}
			<img src={src} alt={alt} title={title} />
			{figcaption ? <figcaption>{figcaption}</figcaption> : null}
		</figure>
	);
};

export default Figure;
