/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext } from 'react';
import { StateProvider } from './StateContext';
import { DatabaseContext } from '../Database';

// Tools
// import { removeLocalData } from '../../tools/local-data';

// Actions
import useActionView from './hooks/useActionView';
import useActionData from './hooks/useActionData';

import useAPI from './useAPI';

import views from './views.json';

// Functions
// import { useGetDBData } from './functions';

const State = ({ children }) => {
	const { submit, get } = useContext(DatabaseContext);

	// View is used to tell components if they should render and what to render.
	// Map state and title state. Title being the start page.
	const [appView, setAppView] = useActionView(views, views[0]);

	// Data is user generated data by fullfilling the steps.
	const [dbData, setDBData] = useActionData({});

	// Data is user generated data by fullfilling the steps.
	const [userData, setUserData] = useActionData({});

	const [submission, setSubmission] = useActionData({});

	const [loadState, setLoadState] = useState(false);

	const api = useAPI(get, dbData, setDBData);

	// const [componentProps, setComponentProps] = useState({});

	// useEffect(() => {
	// 	const components = Object.keys(appDataJSON);
	// 	const componentProps = {};
	// 	for (const key of components) {
	// 		componentProps[key] = { ...appDataJSON[key].default, ...appDataJSON[key][appView] };
	// 	}
	// 	setComponentProps(componentProps);
	// }, [appView]);

	return (
		<StateProvider
			value={{
				appView,
				setAppView,

				userData,
				setUserData,

				setLoadState,

				api,

				submit: async () => {
					const result = await submit(userData);
					if (result.status === 'success') {
						setSubmission({ ...userData, id: result.key });
						return result;
					}
					return;
				}
			}}
		>
			{children({ ready: loadState, submission })}
		</StateProvider>
	);
};

export default State;

// if (!appData.coordinates || !appData.participants || !appData.length) return;
// const result = await submit(appData);

// /* const result = runTest(submit, appData, 500); */
// if (result.status === 'success') {
// 	setAppSteps('Receipt');
// }

// // Refresh data
// const keys = ['trips', 'counties', 'totals'];
// for (const key of keys) {
// 	removeLocalData(key);
// 	setDBData(dbData => ({ ...dbData, ...{ [key]: {} } }));
// }
// },

// // DB Data
// getDBData: getDBData,

// // Loading
// loadState,
// setLoadState: state => setLoadState(state)
