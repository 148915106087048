import React, { useState, useCallback } from 'react';
import { Link } from 'react-navi';
import cc from 'classcat';

import Block from '../../Layout/Block';
import Container from '../../Layout/Container';
import Width from '../../Layout/Width';

import Figure from '../../Elements/Figure';

import logo from '../../../media/logo/arla-protein-logo-80x57.svg';
import { Layout } from '../../Layout';

const Menu = ({ routes = [] }) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggleOpen = useCallback(() => setIsOpen(isOpen => !isOpen), []);
	const close = useCallback(() => setIsOpen(false), []);
	// const open = useCallback(() => setIsOpen(true), []);

	return (
		<nav id="menu" className={cc(['menu', { 'menu-open': isOpen }])}>
			<Container size={'s'}>
				<Width>
					<div className="menu-bar">
						<Link onClick={() => (isOpen ? close() : null)} href="/" className="menu-logo">
							<Figure type={'center'} src={logo} />
						</Link>
						<Link
							onClick={() => (isOpen ? close() : null)}
							href="/"
							className="menu-bar-item menu-bar-anchor anim-ex"
						>
							<span className="menu-bar-item-text anim">Arla treningsglede</span>
						</Link>

						{/* https://css-tricks.com/do-you-need-an-icon-only-button-without-screwing-up-the-accessibility/ */}
						<button
							id="menu-toggle"
							className="menu-bar-item menu-bar-btn"
							type="button"
							aria-expanded="false" // Toggle this to true when open
							onClick={toggleOpen}
						>
							<span className="menu-bar-item-text anim">Meny</span>
							<span className="menu-bar-item-icon">
								<svg />
							</span>
						</button>
					</div>
					<div className="menu-body">
						<div className="comp">
							<div>
								<div className="comp-body">
									<Width>
										{routes.map(({ path, text }) => (
											<Block size={'m'} key={path}>
												<Layout>
													<Link onClick={close} href={path} className="menu-anchor">
														{text}
													</Link>
												</Layout>
											</Block>
										))}
									</Width>
								</div>
							</div>
						</div>
					</div>
				</Width>
			</Container>
		</nav>
	);
};

export default Menu;
