import React, { useContext } from 'react';

// Elements
import Btn from '../../Elements/Btn';
import { CardDiv } from '../../Elements/Card';

// Layout
import Block from '../../Layout/Block';
import Width from '../../Layout/Width';
import Layout from '../../Layout/Layout';
import Container from '../../Layout/Container';

import { StateContext } from '../../State';
import { useCurrentRoute } from 'react-navi';

import categories from '../../../data/categories.json';

const Form = () => {
	const { userData = {} } = useContext(StateContext);
	const {
		data: { id }
	} = useCurrentRoute();

	const {
		'group-category': category,
		'group-description': description,
		'group-location': location,
		'group-name': name,
		'group-url': url
	} = userData;
	return (
		<>
			<Block>
				<Width size={'m'}>
					<h1 className="type-t2 type-center type-uppercase type-br">
						Gruppen er lagt til!
						<br />
						Takk for ditt bidrag.
					</h1>
				</Width>
			</Block>
			<Block>
				<Width size={'s'}>
					<Block backgroundColor={'0d0a0e'}>
						<Container size={'mm'}>
							<CardDiv {...{ category: categories[category], description, location, name, url, id }} />
						</Container>
					</Block>
				</Width>
			</Block>
			<Block>
				<Width size={'s'}>
					<Layout>
						<Btn.Link href="/finn-treningsgruppe">Finn treningsgruppe</Btn.Link>
					</Layout>
				</Width>
			</Block>
		</>
	);
};

export default Form;
