import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

const Col = ({ type = '2', middle = false, center = false, children }) => (
	<div className={cc([`col-${type}`, { 'col-middle': middle, 'col-center': center }])}>
		{children.map ? (
			children.map((child, index) => <div key={child.key ? child.key : index}>{child}</div>)
		) : (
			<div>{children}</div>
		)}
	</div>
);

Col.propTypes = {
	type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	middle: PropTypes.bool,
	center: PropTypes.bool
};

export default Col;

// {children.map
// 	? children.map((child, index) => <div key={child.key ? child.key : index}>{child}</div>)
// 	: children.props.children.map
// 	? children.props.children.map(child => <div key={child.key}>{child}</div>)
// 	: children}
