import React, { useState, useEffect, useMemo, useRef } from 'react';
import cc from 'classcat';
import useDelay from '../../hooks/useDelay';
import useClickOutside from '../../hooks/useClickOutside';
import useKeypress from '../../hooks/useKeypress';

const Modal = ({ closeButton = true, closeFunc, open = false, transitionDuration = 0, children }) => {
	const [triggerOpen, setTriggerOpen] = useState(open);
	const ref = useRef({});
	const modalOpen = useDelay({ value: triggerOpen, delay: transitionDuration });

	const modalTransition = useMemo(() => modalOpen !== triggerOpen, [triggerOpen, modalOpen]);
	const transitionClasses = useMemo(
		() => ({
			'modal-transition': modalTransition,
			'modal-closing': modalTransition && !triggerOpen,
			'modal-opening': modalTransition && triggerOpen
		}),
		[modalTransition, triggerOpen]
	);

	useClickOutside(ref, closeFunc);
	useKeypress(27, closeFunc);

	useEffect(() => {
		setTriggerOpen(open);
	}, [open]);

	return (
		<div className={cc(['modal', { 'modal-open': modalOpen, 'modal-close': !modalOpen, ...transitionClasses }])}>
			<div>
				<div ref={_ref => (ref.current = _ref)} className="modal-body">
					{children}
					{closeButton ? (
						<button
							type="button"
							onClick={closeFunc ? closeFunc : () => setTriggerOpen(false)}
							className="modal-close"
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default Modal;
