import React from 'react';
import PropTypes from 'prop-types';

const Width = ({ size = 'l', children }) => <div className={`width-${size}`}>{children}</div>;

Width.propTypes = {
	size: PropTypes.string
};

export default Width;
