import firebase from 'firebase/app';
import 'firebase/database';

export default () => {
	firebase.initializeApp({
		apiKey: process.env.REACT_APP_FIREBASE_KEY,
		databaseURL: process.env.REACT_APP_FIREBASE_URL
	});
	return firebase;
};
