import { useMemo } from 'react';

const useFilter = (source, propNames = [], callback) =>
	useMemo(
		() =>
			source
				.filter(obj =>
					propNames.length > 1
						? propNames.reduce((prev, current) => {
								return typeof prev === 'string' ? obj[prev][current ? current : null] : prev[current];
						  })
						: obj[propNames[0]]
				)
				.map(callback ? callback : arr => arr),
		[propNames, source, callback]
	);

export default useFilter;
