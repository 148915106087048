import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import './scss/global.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import Database from './Components/Database';
import State from './Components/State';

ReactDOM.render(
	<Database>{() => <State>{({ submission }) => <App submission={submission} />}</State>}</Database>,
	document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
