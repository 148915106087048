import { useState, useEffect } from 'react';

const useDelay = ({ value, delay = 350, onMountValue = null }) => {
	const [delayedValue, setDelayedValue] = useState(onMountValue);

	useEffect(() => {
		const token = setTimeout(() => setDelayedValue(value), delay);
		return () => clearTimeout(token);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return delayedValue;
};

export default useDelay;
