import React, { useContext, useMemo, useCallback } from 'react';
import { mount, route, redirect } from 'navi';
import Pages from '../Pages/PageComponents';
import { StateContext } from '../State';
import NotFound from '../Global/NotFound';
import Cookies from '../Pages/Cookies';
import GDPR from '../Pages/GDPR/GDPR';

const search = api => (req, groups) => {
	const {
		query: { search = '' }
	} = req;
	if (!search) return groups;
	window.gtag('event', search, { event_category: 'search' });
	return api.search(groups, search);
};

const limit = (req, groups = []) => {
	const {
		query: { limit = 9 }
	} = req;

	if (limit === 0 || groups.length < limit) return { groups, more: false };
	const more = groups.length > limit;

	return { groups: groups.splice(0, limit), more, nextLimit: parseInt(limit) + 9 };
};

let timeout;
const ga = (arg = {}) => {
	clearTimeout(timeout);
	timeout = setTimeout(() => {
		window.gtag('config', 'UA-143630156-1', { page_path: window.location.pathname });
	}, 1000);
	return arg;
};

export default () => {
	const { api } = useContext(StateContext);
	const { Home, Search, Submit, Receipt } = Pages;
	const doSearch = useCallback(search(api), []);

	return useMemo(
		() =>
			mount({
				'*': route({
					view: <NotFound />,
					getData: ga
				}),
				'/': route({
					view: <Home key={'home'} />,
					getData: ga
				}),
				'/finn-treningsgruppe': route({
					view: <Search key={'search'} />,
					getData: async req => {
						ga();
						const results = limit(req, doSearch(req, await api.getGroups()));
						return results;
					}
				}),
				'/finn-treningsgruppe/:id': route({
					view: <Search key={'search'} />,
					getData: async req => {
						ga();
						const {
							params: { id }
						} = req;
						const { groups, ...params } = limit(req, doSearch(req, await api.getGroups()));
						const group = await api.getGroup(id);
						return { group: { ...groups.find(({ key }) => key === id), ...group, id }, groups, ...params };
					}
				}),
				'/registrer': route({
					view: <Submit key={'submit'} />,
					getData: ga
				}),
				'/registrer-suksess': redirect('/'),
				'/registrer-suksess/:id': route({
					view: <Receipt key={'receipt'} />,
					getData: req => {
						ga();
						return { id: req.params.id };
					}
				}),
				'/cookies': route({
					view: <Cookies key={'cookies'} />,
					getData: ga
				}),
				'/personvern': route({
					view: <GDPR key={'gdpr'} />,
					getData: ga
				})
			}),
		[api, doSearch]
	);
};
