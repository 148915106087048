import React from 'react';
import { useCurrentRoute, useLoadingRoute } from 'react-navi';
import cc from 'classcat';
import TinyTransition from 'react-tiny-transition';
import Spinner from '../../Spinner/Spinner';

const Main = ({ children }) => {
	const loadingRoute = useLoadingRoute();
	const route = useCurrentRoute();
	const key = route.views[0] ? route.views[0].key : null;
	return (
		<div className={cc(['main', key ? `main-${key}` : null])}>
			<div>
				<div className="comp">
					<div>
						<div className="comp-body">{children}</div>
					</div>
				</div>
				<TinyTransition duration={100}>{!!loadingRoute ? <Spinner /> : null}</TinyTransition>
			</div>
		</div>
	);
};

export default Main;
