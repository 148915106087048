import React from 'react';
import PropTypes from 'prop-types'
import Input from './Input';

const InputNumber = ({ min = 0, max = null, ...props }) => <Input type="number" min={min} max={max} {...props} />;

InputNumber.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
}

export default InputNumber;
