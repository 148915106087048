import React from 'react';
import Block from '../../Layout/Block';
import { Editor } from '../../Layout';

const Card = ({ Tag = 'button', title, description = null, children, ...props }) => {
	return (
		<Tag className="card" {...props}>
			<Block>
				<Block size="s">
					<h3 className="card-title">{title}</h3>
				</Block>
				{description ? (
					<Block size="s">
						<Editor>
							<p>{description}</p>
						</Editor>
					</Block>
				) : null}
			</Block>
			{children}
		</Tag>
	);
};

export default Card;
