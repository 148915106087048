import React from 'react';
import { Link } from 'react-navi';
import cc from 'classcat';

import Container from '../../Layout/Container';
import Width from '../../Layout/Width';
import Block from '../../Layout/Block';

const Footer = ({ page, routes }) => (
	<div className={cc(['footer', page ? `footer-${page}` : null])}>
		<Block backgroundColor={'0d0a0e'}>
			<Container size={'s'}>
				<Width size={'s'}>
					<p className="type-t6 type-center">
						{routes.map(({ path, text, ga = false }, i) => (
							<React.Fragment key={path}>
								<Link
									href={path}
									onClick={
										ga
											? () => {
													window.gtag('event', 'Click on footer link', {
														event_label: 'Arla produktside',
														event_category: 'External link click'
													});
											  }
											: null
									}
									target="_blank"
									rel="noopener noreferrer"
								>
									{text}
								</Link>
								{i < routes.length - 1 ? ' / ' : ''}
							</React.Fragment>
						))}
					</p>
				</Width>
			</Container>
		</Block>
	</div>
);

export default Footer;
