import React from 'react';
import PropTypes from 'prop-types';
import InputWrap from './InputWrap';

const Select = React.memo(
	({
		options = [],
		maxWidth,
		id,
		isValid,
		value,
		onChange,
		label,
		changeHandler = ({ target: { value } }) => value
	}) => {
		return (
			<InputWrap {...{ maxWidth, value, isValid, label, id }}>
				<select
					id={`input-${id}`}
					onChange={e => {
						onChange({ value: changeHandler(e), type: 'text', id });
					}}
					value={value}
					className="input-field"
				>
					{options.map(({ value, disabled = false, text }) => (
						<option key={value} value={value} disabled={disabled}>
							{text}
						</option>
					))}
				</select>
			</InputWrap>
		);
	}
);

Select.propTypes = {
	options: PropTypes.array,
	label: PropTypes.string,
	id: PropTypes.string,
	isValid: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	onChange: PropTypes.func,
	changeHandler: PropTypes.func
};

export default Select;
