/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useCallback, useMemo } from 'react';
import validator from '../../tools/validation';

export const useFormData = validate =>
	useReducer(
		useCallback(
			(state, { id, value, required = false, validation = state[id].validation }) =>
				(state = {
					...state,
					[id]: { value, required, validation, isValid: !validate ? null : validator(validation)(value) }
				}),
			[]
		),
		{}
	);

export const useValidateForm = (validator, validate) =>
	useMemo(() => {
		if (!validate) return true;
		if (!validator) return false;
		const keys = Object.keys(validator);

		for (const key of keys) {
			const valid = validator[key].isValid;
			if (!valid) return false;
		}

		return true;
	}, [validator]);
