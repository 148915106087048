/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Modal from './Modal';
import { CardDiv } from '../../Elements/Card';
const ModalCard = React.memo(({ open, closeFunc, ...props }) => {
	return (
		<Modal open={open} closeFunc={closeFunc}>
			<CardDiv {...props} />
		</Modal>
	);
});

export default ModalCard;
