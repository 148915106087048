/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from 'react';

// Context
import { DatabaseProvider } from './DatabaseContext';

// Hooks
import useWait from '../hooks/useWait';
import useFirebase from '../../Firebase/useFirebase';

// Functions
import { useActions } from './functions';

// Tools
import promise from '../../tools/promise';
import array2Obj from '../../tools/array2Obj';

// Data
import PATHS from './PATHS';

const Database = ({ children }) => {
	const activeOperations = useRef({});
	// Sets loading state
	const [isLoading, setIsLoading] = useState(false);

	// Connection triggers connection to database
	const [connection, setConnection] = useState(false);

	// Firebase waits to connect until user needs database
	const firebase = useFirebase(connection);

	// Actions waits for Firebase to be defined
	const actions = useActions(firebase);

	// Endpoints get defined after actions are set up
	const endpoints = useRef();

	// isReady triggers the promise inside useWait,
	// will only trigger after endpoints, actions and firebase is defined.
	const [isReady, setIsReady] = useState(null);
	const awaitReady = useWait(isReady);

	const connect = useCallback(() => {
		setConnection(true);
		setIsReady(false);
	}, []);

	const disconnect = useCallback(() => {
		setConnection(false);
		setIsReady(null);
	}, []);

	useEffect(() => {
		if (!actions) return;

		//endpoints.current = {PATH: actions(PATH), ...}
		endpoints.current = array2Obj(Object.keys(PATHS), (key, obj) => ({ [key]: actions(key) }));
	}, [actions]);

	useEffect(() => {
		if (!endpoints.current || !actions || !firebase || isReady) return;
		setIsReady(true);
	}, [actions, firebase, endpoints.current, isReady]);

	return (
		<DatabaseProvider
			value={{
				submit: promise(
					async (
						resolve,
						reject,
						{
							'group-category': category,
							'group-description': description,
							'group-location': location,
							'group-name': name,
							'group-url': url
						}
					) => {
						setIsLoading(true);
						if (!connection) connect();
						const ready = await awaitReady;
						if (!ready) {
							disconnect();
							return resolve({ status: 'error', type: 'timeout' });
						} // Timeout -- Should throw error

						const { group, groups, timestamp } = endpoints.current;

						const catchCallback = () => {
							setIsLoading(false);
							resolve({ status: 'error' });
						};

						const groupRef = await group.push();
						const key = groupRef.key;

						await group
							.update({
								ref: groupRef,
								data: {
									description,
									url
								},
								timestamp: false
							})
							.catch(catchCallback);

						await groups
							.set({
								childKey: key,
								data: {
									name,
									location,
									category: parseInt(category)
								},
								timestamp: false
							})
							.catch(catchCallback);

						await timestamp({
							childKey: key
						}).catch(catchCallback);

						setIsLoading(false);
						resolve({ status: 'success', key });
					}
				),
				get: useCallback(
					promise(async (resolve, reject, { endpoint, shouldLoad, methods }) => {
						if (!connection) connect();
						setIsLoading(shouldLoad);

						const ready = await awaitReady;
						if (!ready) {
							disconnect();
							return resolve({ status: 'error', type: 'timeout', err: console.error('Request timed out.') });
						} // Timeout -- Should throw error

						if (!endpoints.current[endpoint]) {
							return resolve({ status: 'error', err: console.error('Undefined endpoint used in Database.') });
						}

						let snapshot;
						if (activeOperations.current[endpoint]) {
							snapshot = activeOperations.current[endpoint];
						}

						if (!snapshot) {
							snapshot = endpoints.current[endpoint].get({ methods }).catch(e => resolve({ status: 'error', err: e }));
							activeOperations.current[endpoint] = snapshot;
						}

						snapshot = await snapshot;
						activeOperations.current[endpoint] = null;

						setIsLoading(false);
						resolve({ status: 'success', data: snapshot.val(), key: snapshot.key });
					}),
					[]
				)
			}}
		>
			{children(isLoading)}
		</DatabaseProvider>
	);
};

export default Database;
