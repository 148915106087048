import React from 'react';
import Block from '../../Layout/Block';
import Card from './Card';
import CardRows from './CardRows';

const CardButton = ({ name, location, onClick, category }) => (
	<Card title={name} Tag="button" onClick={onClick}>
		<Block size={'t'}>
			<CardRows rows={[['Sted: ', location]]} />
		</Block>
		<Block size={'t'}>
			<CardRows rows={[['Type: ', category]]} />
		</Block>
	</Card>
);

export default CardButton;
