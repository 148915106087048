import React from 'react';
import { Block, Width, Editor } from '../../Layout';
import { Link } from 'react-navi';

const Cookies = () => {
	return (
		<>
			<Block>
				<Width size="s">
					<Editor>
						<h3>Retningslinjer for informasjonskapsler (Cookies)</h3>
						<h4>Hva er informasjonskapsler?</h4>
						<p>
							Denne nettsiden, som alle andre nettsider, fungerer best når du tillater informasjonskapsler. Når du
							besøker denne siden, kan vi sende (og lagre) en informasjonskapsel på din enhet. En informasjonskapsel er
							en liten tekstfil som sendes via en webserver til en nettleser (for eksempel Firefox, Chrome eller
							Safari). Informasjonskapselen gjør det mulig for nettsiden eller webserveren å samle spesifikk, men
							begrenset informasjon fra nettleseren om besøkeren (altså deg).
						</p>
						 <h4>Hvordan bruker vi informasjonskapsler?</h4>
						<p>
							På denne siden bruker vi tredjepartsinformasjonskapsler fra Google for å samle og lagre anonym statistikk
							om søkemønster eller atferd og bygge opp en demografisk profil. Denne informasjonen samles inn sammen med
							annen besøksinformasjon for å danne en samlet analyserapport. Det er viktig å bemerke at denne
							informasjonen ikke er personlig identifiserbar, og at den kun brukes til å rapportere eller føre
							statistikk.
						</p>
						 <h4>Hva gjør du hvis du ikke ønsker informasjonskapsler?</h4>
						<p>
							Du er ikke forpliktet til å godta informasjonskapsler fra denne siden, og de fleste nettlesere lar deg
							deaktivere informasjonskapsler. Hvis du vil finne ut hvordan du gjør dette, kan du se i hjelpemenyen i din
							nettleser eller kontakte din systemadministrator. Vær oppmerksom på at dersom du deaktiverer
							informasjonskapsler, vil noen tjenester og sider på denne nettsiden kanskje ikke være tilgjengelige for
							deg, eller de vil kanskje ikke fungere som de skal.
						</p>
						<p>
							Besøk <Link href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</Link> for mer
							informasjon om informasjonskapsler og hvordan du hindrer informasjonskapsler i å bli installert, eller
							hvordan du sletter eksisterende informasjonskapsler fra harddisken.
						</p>
					</Editor>
				</Width>
			</Block>
		</>
	);
};

export default Cookies;
