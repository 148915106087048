import React from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

const InputWrap = ({ isValid, value, maxWidth = null, id, label, icon, children }) => {
	const classes = {
		'input-success': isValid !== null && value && isValid,
		'input-error': isValid !== null && value && !isValid,
		'input-value': value
	};
	return (
		<div style={maxWidth ? { maxWidth, margin: '0 auto' } : {}} className={cc(['input-wrap', classes])}>
			{icon ? <div className="input-icon" style={{ backgroundImage: icon }} /> : null}
			{children}
			{label ? (
				<label className="input-label" htmlFor={`input-${id}`} dangerouslySetInnerHTML={{ __html: label }} />
			) : null}
		</div>
	);
};

InputWrap.propTypes = {
	isValid: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	maxWidth: PropTypes.string
};

export default InputWrap;
