import React from 'react';

// Layout
import { Block, Layout, Editor } from '../../Layout';

// Elements
import Btn from '../Btn';

// Components
import Card from './Card';
import CardRows from './CardRows';

const CardDiv = ({
	url,
	name,
	category,
	description,
	location,
	btnText = 'Gå til treningsgruppens Facebook-side',
	id
}) => {
	return (
		<Card title={name} Tag="div">
			<Block size="t">
				<Layout center={false} unknown={true}>
					<CardRows rows={[['Type: ', category], ['Sted: ', location]]} />
				</Layout>
			</Block>
			<Block size={'s'}>
				<Editor>
					<p>{description}</p>
				</Editor>
			</Block>
			{url ? (
				<Block size={'m'}>
					<Btn.A
						type="tert"
						onClick={() => {
							window.gtag('event', url, { event_label: 'Facebook-link', event_category: 'External link click' });
						}}
						href={url}
					>
						{btnText}
					</Btn.A>
				</Block>
			) : null}
			<Block size={'s'}>
				<div className="type-t6 type-uppercase type-gray type-right">ID: {id}</div>
			</Block>
		</Card>
	);
};
export default CardDiv;
