export default {
	timestamp: {
		path: '/timestamp',
		write: true,
		read: false
	},
	groups: {
		path: '/groups',
		write: true,
		read: true
	},
	group: {
		path: '/group',
		write: true,
		read: true
	}
};
