import React, { useMemo } from 'react';
import { useCurrentRoute, useNavigation } from 'react-navi';

import Btn from '../../Elements/Btn';

import { Editor, Block, Col, Layout, Width } from '../../Layout';
import { CardButton } from '../../Elements/Card';
import { ModalCard } from '../../Global/Modal';
import SearchInput from '../../SearchInput';

const Search = React.memo(() => {
	const {
		data: { groups, group, more, nextLimit },
		url: { query: { search, limit } = {} }
	} = useCurrentRoute();

	const navigation = useNavigation();
	const params = useMemo(() => ({ ...(search && search !== '' ? { search } : {}), ...(limit ? { limit } : {}) }), [
		search,
		limit
	]);

	return (
		<>
			<Block>
				<Width size={'s'}>
					<h2 className="type-t2 type-center type-uppercase">Finn trenings&shy;gleden sammen med andre</h2>
				</Width>
			</Block>

			<Block size={'m'}>
				<Width size={'s'}>
					<SearchInput
						onChange={value =>
							navigation.navigate({
								pathname: '/finn-treningsgruppe',
								query: value ? { search: value } : {},
								replace: true
							})
						}
						debounceDelay={100}
						placeholder={'Søk etter sted eller treningsform'}
						defaultValue={search}
					/>
				</Width>
			</Block>
			<Block size={'h'}>
				<Width size={'m'}>
					{groups.length > 0 ? (
						<Col type={'3'}>
							{groups.map(card => (
								<CardButton
									key={card.key}
									onClick={() =>
										navigation.navigate({
											pathname: `/finn-treningsgruppe/${card.key}`,
											query: params
										})
									}
									{...card}
								/>
							))}
						</Col>
					) : (
						<Editor>
							<h3 className="type-center">Uff da! Her fant vi ingenting. Prøv gjerne igjen med nye søkeord.</h3>
						</Editor>
					)}
				</Width>
			</Block>
			{groups.length > 0 ? (
				<Block>
					<Width size={'s'}>
						<Layout>
							<Btn
								onClick={() =>
									navigation.navigate({
										pathname: '/finn-treningsgruppe',
										query: { ...params, ...{ limit: nextLimit } }
									})
								}
								type={'sec'}
								disabled={!more}
								meta={{ after: '+' }}
							>
								Last inn flere
							</Btn>
						</Layout>
					</Width>
				</Block>
			) : null}
			{!!group ? (
				<ModalCard
					closeFunc={() =>
						navigation.navigate({
							pathname: '/finn-treningsgruppe',
							query: params
						})
					}
					open={!!group}
					{...(group ? { ...group } : {})}
				/>
			) : null}
		</>
	);
});

export default Search;
